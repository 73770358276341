import React from "react";
import AuthNegociosLayout from "../../layouts/AuthNegociosLayout";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Seo from "../../assets/components/seo";
import Footer from "../../assets/components/Footer";
import Navbar from "../../assets/components/Navbar";
import { MailOutline } from "@mui/icons-material";

const Success = () => {
  const correo =
    typeof window !== "undefined" && window.localStorage.getItem("correo");

  return (
    <Container maxWidth={false}>
      <Navbar />
      <Grid
        container
        justifyContent="center"
        minHeight="100vh"
        alignItems="center"
      >
        <Grid item maxWidth={500}>
          <Card component="form">
            <CardHeader
              title={
                <Stack alignItems="center">
                  <MailOutline fontSize="large" />
                  <Typography textAlign="initial" variant="h4" component="h1">
                    Confirma tu correo electrónico
                  </Typography>
                </Stack>
              }
              subheader={
                <Typography variant="body2" color="GrayText" marginTop={1}>
                  Hemos enviado un magic link para iniciar sesión, al correo
                  electrónico que usaste cuando te registraste en el formulario
                  de compradores y expositores.
                </Typography>
              }
            />
            <CardContent>Enlace enviado a {correo}</CardContent>
          </Card>
        </Grid>
      </Grid>
      <Footer />
    </Container>
  );
};

export default Success;
